import React, {Component} from "react";
import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection6";
import PromoSection from "../components/PromoSection";
import Testimonial from "../components/Testimonial";
import FeatureSection from "../components/Features/Feature3";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import FooterSection from "../components/Footer";

class Theme extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection/>
                    <PromoSection/>
                    <TrustedCompany/>
                    <FeatureSection/>
                    <Testimonial/>
                </div>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default Theme;
