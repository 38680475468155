import React from "react";
import i18n from "i18next";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      showMessage2: false,
      showMessage3: false,
      showMessage4: false
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }
  onButtonClickHandler = () => {
    this.setState({ showMessage: !this.state.showMessage });
  };
  onButtonClickHandler2 = () => {
    this.setState({ showMessage2: !this.state.showMessage2 });
  };
  onButtonClickHandler3 = () => {
    this.setState({ showMessage3: !this.state.showMessage3 });
  };
  onButtonClickHandler4 = () => {
    this.setState({ showMessage4: !this.state.showMessage4 });
  };

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    {i18n.t('text107')} <br />
                    <span>{i18n.t('text108')}</span>
                  </h2>
                  <p>
                    {i18n.t('text109')}
                  </p>
                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-car"></span>
                          {i18n.t('cabs_software')}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-truck"></span>
                          {i18n.t('shipment_software')}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-plus"></span>
                          {i18n.t('add')}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane" id="tab6-2">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              {(!this.state.showMessage) ?
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler} className="ti-arrow-circle-right m-1"></span> :
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler} className="ti-arrow-circle-down m-1"></span>}
                              <h5 style={{cursor:"pointer"}} onClick={this.onButtonClickHandler} className="mb-0 h5 hoverText">{i18n.t('text110')}</h5>
                            </div>
                          </li>
                          {this.state.showMessage && <div className="d-flex">
                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature1')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature2')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature3')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature4')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature5')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature6')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature7')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature8')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature9')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature10')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature11')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature12')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature13')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature14')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature15')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature16')}
                                </p>
                              </div>
                            </div>


                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature17')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature18')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature19')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature20')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature21')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature22')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature23')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature24')}
                                </p>
                              </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature25')}
                              </p>
                            </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature26')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature27')}
                                </p>
                              </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature28')}
                              </p>
                            </div>
                            </div>
                          </div>}<hr style={{color: "black"}} />
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              {(!this.state.showMessage2) ?
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler2} className="ti-arrow-circle-right m-1"></span> :
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler2} className="ti-arrow-circle-down m-1"></span>}
                              <h5 style={{cursor:"pointer"}} onClick={this.onButtonClickHandler2} className="mb-0 h5 hoverText">{i18n.t('text111')}</h5>
                            </div>
                          </li>
                          {this.state.showMessage2 && <div className="d-flex">
                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature29')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature9')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature30')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature31')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature32')}
                                </p>
                              </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature33')}
                              </p>
                            </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature14')}
                              </p>
                            </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature26')}
                              </p>
                            </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature34')}
                              </p>
                            </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature18')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature20')}
                                </p>
                              </div>
                            </div>

                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature27')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature35')}
                                </p>
                              </div><div className="d-flex align-items-center">
                              <div className="badge">
                                <span className="ti-check"></span>
                              </div>
                              <p className="mb-0">
                                {i18n.t('feature24')}
                              </p>
                            </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature36')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature10')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature37')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature38')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature6')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature19')}
                                </p>
                              </div>
                            </div>
                          </div>}
                          <hr style={{color: "black"}} />
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              {(!this.state.showMessage3) ?
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler3} className="ti-arrow-circle-right m-1"></span> :
                                <span style={{cursor:"pointer"}} onClick={this.onButtonClickHandler3} className="ti-arrow-circle-down m-1"></span>}
                              <h5 style={{cursor:"pointer"}} onClick={this.onButtonClickHandler3} className="mb-0 h5 hoverText">{i18n.t('text112')}</h5>
                            </div>
                          </li>
                          {this.state.showMessage3 && <div className="d-flex">
                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature20')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature9')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature39')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature40')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature41')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature17')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature24')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature18')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature14')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature15')}
                                </p>
                              </div>
                            </div>

                            <div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature42')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature43')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature44')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature45')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature46')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature47')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature48')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature49')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature50')}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="badge">
                                  <span className="ti-check"></span>
                                </div>
                                <p className="mb-0">
                                  {i18n.t('feature16')}
                                </p>
                              </div>
                            </div>
                          </div>}
                        </ul>
                      </div>
                      <div className="tab-pane active" id="tab6-3">
                        <div className="tab-content feature-tab-content">
                          <ul className="list-unstyled">
                            <hr style={{color:"black"}}/>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <h5  className="mb-0 h5">{i18n.t('text113')}</h5>
                            </div>
                          </li>
                            <div className="d-flex">
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature51')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature52')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature53')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature54')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature55')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature56')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature57')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature58')}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature59')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature60')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature61')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature62')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature63')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature64')}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="badge">
                                    <span className="ti-check"></span>
                                  </div>
                                  <p className="mb-0">
                                    {i18n.t('feature65')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-4">
                        <div className="tab-pane" id="tab6-3">
                          <div className="single-feature">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="mb-0">{i18n.t('text114')}</h5>
                            </div><p className="h6">{i18n.t('text115')}</p><br />
                              <a href="/contact" className="btn solid-btn">{i18n.t('text65')}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                      src="img/image-10.jpg"
                      alt="download"
                      className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
