import React from "react";
import { connect } from "react-redux";
import _data from "../../_util/pricing";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>{i18n.t('text74')}</h2>
                  <p className="lead">{i18n.t('text75')}</p>
                </div>
              </div>
            </div>
            <div className="row equal">
              <div className="col-md-4 col-lg-4">
                <div className="single-promo single-promo-hover single-promo-1 rounded  p-5" style={{height:350}}>
                  <div className="circle-icon mb-5">
                    <span style={{color:'#085ca7', fontSize:50}} className={"ti-apple"}/>
                  </div>
                  <h5>{i18n.t('text78')}</h5>
                  <p>{i18n.t('text79')}</p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5" style={{height:350}}>
                  <div className="circle-icon mb-5">
                    <span style={{color:'#085ca7', fontSize:50}} className={"ti-announcement"}/>
                  </div>
                  <h5>{i18n.t('text80')}</h5>
                  <p>{i18n.t('text81')}</p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4" >
                <div className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5" style={{height:350}}>
                  <div className="circle-icon mb-5">
                    <span style={{color:'#085ca7', fontSize:50}} className={"ti-bar-chart"}/>
                  </div>
                  <h5>{i18n.t('text84')}</h5>
                  <p>{i18n.t('text85')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(PromoSection));
