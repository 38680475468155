import React from "react";
import i18n from "i18next";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section id="feature2" className="feature-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                      src={this.props.data.img}
                    alt="download"
                    className="img-fluid"
                      width="269"
                      height="1321"
                      style={{border: "10px solid black", borderRadius: "30px"}}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    {i18n.t(this.props.data.title)}
                  </h2>
                  <p className='badge badge-primary badge-pill'>
                    {i18n.t(this.props.data.description)}
                  </p>

                  <div className="feature-content-wrap mt-2">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      {this.props.data.buttons.map((item, index) => {
                        return  <li key={i18n.t(item.name)} className="nav-item">
                        <a
                          className={`nav-link ${index ===0?'active':''} h6`}
                          href={item.href}
                          data-toggle="tab"
                        >
                          <span className={item.icon}/>
                          {i18n.t(item.name)}
                        </a>
                      </li>
                      })}
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab1">
                        <ul className="list-unstyled">
                          {this.props.data.tab1.map((item, index) => {
                            return <li key={i18n.t(item.name)} className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="badge badge-circle badge-primary mr-3">
                                    <span className="ti-check"/>
                                  </div>
                                </div>
                                <div>
                                  <p className="mb-0 font-weight-bolder text-dark">{i18n.t(item.name)}</p>
                                  <small className="mb-0">{i18n.t(item.description)}</small>
                                </div>
                              </div>
                            </li>
                          })}
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab2">
                          {this.props.data.tab2.map((item, index) => {
                            return <div key={i18n.t(item.name)} className="single-feature">
                              <div className="d-flex align-items-center mb-2">
                                <span className={`${item.icon} rounded mr-3 icon`}/>
                                <h5 className="mb-0">{i18n.t(item.name)}</h5>
                              </div>
                              <p>
                                {i18n.t(item.description)}
                              </p>
                            </div>
                          })}
                      </div>
                      <div className="tab-pane" id="tab3">
                        <div className="row">
                          {this.props.data.tab3.map((item, index) => {
                            return <div key={i18n.t(item.name)} className="col single-feature mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <span className={`${item.icon} rounded mr-3 icon`}/>
                                <h5 className="mb-0">{i18n.t(item.name)}</h5>
                              </div>
                              <p>
                                {i18n.t(item.description)}
                              </p>
                            </div>
                          })}
                        </div>
                      </div>
                      <div className="tab-pane" id="tab4">
                        <div className="d-flex align-items-center mb-2">
                          <h5 className="mb-0">{i18n.t(this.props.data.tab4.title)}</h5>
                        </div>
                        <p>
                          {i18n.t(this.props.data.tab4.description)}
                        </p>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
