import React from "react";
import i18n from "i18next";
import {withTranslation} from 'react-i18next';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'es'
        };
    }

    componentDidMount() {
        let language = localStorage.getItem("language");
        if (language) {
            this.setState({language: language})
            i18n.changeLanguage(language).catch((e) => {
                console.log(e);
            });
        }
    }

    changeLang = async (lang) => {
        try {
            localStorage.setItem("language", lang);
            this.setState({language: lang})
            await i18n.changeLanguage(lang);
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section gray-light-bg">
                    <div className={"footer-top background-img-2 pt-100 pb-5"}>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-3 mb-3 mb-lg-0">
                                    <div className="footer-nav-wrap text-dark">
                                        <a href="/">
                                            <img
                                                src="img/logo.png"
                                                alt="footer logo"
                                                width="170"
                                                className="img-fluid mb-3"
                                            />
                                        </a>
                                        <p className='mr-4 text-left'>
                                            {i18n.t('text89')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-dark">
                                        <h5 className="mb-3 text-dark">{i18n.t('company')}</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <a href="/about">{i18n.t('text88')}</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/contact">{i18n.t('text90')}</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/pricing">{i18n.t('pricing')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-2 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-dark">
                                        <h5 className="mb-3 text-dark">{i18n.t('text91')}</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <a href="/cabs_software">{i18n.t('cabs_software')}</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/shipment_software">{i18n.t('shipment_software')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-dark">
                                        <h5 className="mb-3 text-dark">{i18n.t('text65')}</h5>
                                        <ul className="list-unstyled support-list">
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-location-pin mr-2"></span>
                                                9 South 35th Street
                                                <br/>
                                                Camden, NJ, United States
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-mobile mr-2"></span>{" "}
                                                <a href="tel:+61283766284">+1 (856) 870-6684</a>
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-email mr-2"></span>
                                                <a href="mailto:info@softnative.dev">info@softnative.dev</a>
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-world mr-2"></span>
                                                <a href="https://softnative.dev/">www.softnative.dev</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-dark">
                                        <h5 className="mb-3 text-dark">{i18n.t('language')}</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                  <span onClick={() => this.changeLang('es')}
                                        style={{
                                            backgroundColor: this.state.language === 'es' ? '#085ca7' : 'transparent',
                                            color: this.state.language === 'es' ? '#fff' : '#000',
                                            borderRadius: 5,
                                            cursor: "pointer"

                                        }}
                                        className="dropdown-item">{i18n.t('spanish')}</span>
                                            </li>
                                            <li className="mb-2">
                                <span onClick={() => this.changeLang('en')}
                                      style={{
                                          backgroundColor: this.state.language === 'en' ? '#085ca7' : 'transparent',
                                          color: this.state.language === 'en' ? '#fff' : '#000',
                                          borderRadius: 5,
                                          cursor: "pointer"
                                      }}
                                      className="dropdown-item">{i18n.t('english')}</span>
                                            </li>
                                        </ul>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom white-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center justify-content-center">
                                <div className="col-md-4 col-lg-4 row justify-content-center">
                                    <a className='copyright-text text-dark mr-4' href="/privacy_policy">{i18n.t('text92')}</a>
                                    <a className='copyright-text text-dark' href="/terms">{i18n.t('text93')}</a>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <p className="copyright-text pb-0 mb-0">
                                        {i18n.t('text94')}
                                        <b> SoftNative Group LLP</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Footer);

