import React from "react";
import i18n from "i18next";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section id={this.props.id} className="feature-section ptb-100 white-bg">
                    <div className="container">
                        <div className="flex-column">
                                <h2>{i18n.t(this.props.data.title)}</h2>
                                <p className="mb-4">{i18n.t(this.props.data.description)}</p>
                            <img src={this.props.data.img} style={{border: "10px solid black", borderRadius: "25px"}} width={'100%'} className="img-full" alt={this.props.data.img}/>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default About;
