import React from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import {withTranslation} from "react-i18next";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="hero-section pt-100 background-img"
        style={{
          backgroundImage: "url('img/about.jpeg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}>
        <div className="container">
            <div className="row text-center align-items-center justify-content-between">
                <div className="col-md-12 col-lg-12">
                    <br/><br/>
                    <div className="hero-content-left text-white mt-5">
                        <h1 className="text-white"><span>SoftNative Group LLP</span></h1>
                        <p style={{fontSize:30}} className="lead">{i18n.t('text116')}</p>
                    </div>
                    <div className="hero-content-left text-white mt-5">
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                     </div>
                </div>
            </div>
        </div>
    </section>
        </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(HeroSection));
