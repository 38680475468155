import React, { Component } from "react";
import { connect } from "react-redux";
import { submitContact } from "../../actions/index";
import i18n from "i18next";
import {withTranslation} from "react-i18next";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      disableContactBtn: false,
      contactBtnText: "send",
      isSend: false,
      contact: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * When we click on Send button, changeBtnText function will help to change text
   * @param contactBtnText
   */
  changeBtnText = contactBtnText => {
    this.setState({ contactBtnText });
  };

  /**
   * Get all form data and set to the state
   * @param contactBtnText
   */
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  /**
   * Submit the form and dispatch to the store
   * @param contactBtnText
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ disableContactBtn: true });


    let url = "https://us-central1-softnative-group.cloudfunctions.net/sendContact"
    fetch(url, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        company: this.state.company,
      }),
    })
      .then(response => response.json())
      .then(data => {
        this.setState({sendSuccess: 'send_success', isSend:true});
        setTimeout(() => {
          this.setState({sendSuccess: ''});
        }, 5000)

      })
      .catch((error) => {
        console.error('Error:', error);
      });

    const contactAction = submitContact(this.state);
    this.props.dispatch(contactAction);
    setTimeout(
      function() {
        this.setState({ disableContactBtn: false });
        this.changeBtnText("Send Message");
        const contactAction = submitContact(this.state);
        this.props.dispatch(contactAction);
        this.setState({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: ""
        });
      }.bind(this),
      3000
    );
  }

  render() {
    return (
      <React.Fragment>
        <section id="contact" className={"contact-us ptb-100 white-bg"}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="section-heading">
                  <h3>{i18n.t('text102')}</h3>
                </div>
                <div className="footer-address">
                  <h6>
                    <strong>{i18n.t('text103')}</strong>
                  </h6>
                  <p>9 South 35th Street Camden, NJ <br/>
                    United States
                  </p>
                  <ul>
                    <li>
                      <span>{i18n.t('text104')}: +1 (856) 870-6684</span>
                    </li>
                    <li>
                      <span>
                        {i18n.t('text105')} :{" "}
                        <a href="mailto:info@softnative.dev">
                          info@softnative.dev
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-7">
                <form
                  method="POST"
                  id="contactForm1"
                  className="contact-us-form"
                  onSubmit={this.handleSubmit}
                >
                  <h5>{i18n.t('text95')}</h5>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={this.state.name}
                          onChange={e => this.handleFormValueChange("name", e)}
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder={i18n.t('text97')}
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={this.state.email}
                          onChange={e => this.handleFormValueChange("email", e)}
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder={i18n.t('text99')}
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={this.state.phone}
                          onChange={e => this.handleFormValueChange("phone", e)}
                          type="text"
                          name="phone"
                          className="form-control"
                          id="phone"
                          placeholder={i18n.t('text100')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={this.state.company}
                          onChange={e =>
                            this.handleFormValueChange("company", e)
                          }
                          type="text"
                          name="company"
                          size="40"
                          className="form-control"
                          id="company"
                          placeholder={i18n.t('text98')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          onChange={e =>
                            this.handleFormValueChange("message", e)
                          }
                          value={this.state.message}
                          name="message"
                          id="message"
                          className="form-control"
                          rows="7"
                          cols="25"
                          placeholder={i18n.t('text101')}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.isSend && (<b style={{color:'green'}}>{i18n.t('message_sent')}</b>)}
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <button
                        type="submit"
                        className="btn solid-btn"
                        id="btnContactUs"
                        disabled={this.state.disableContactBtn}
                        onClick={() => {
                          this.changeBtnText(i18n.t('text106'));
                        }}
                      >
                        {i18n.t(this.state.contactBtnText)}
                      </button>
                    </div>
                  </div>
                </form>
                <p className="form-message"/>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(Contact));
