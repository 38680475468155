import React from "react";
import _data from "../../_util/pricing";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

class TrustedCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trustedCompany: []
    };
  }

  componentDidMount() {
    this.setState({
      trustedCompany: _data.trustedCompanyAboutUs
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className={"client-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>{i18n.t('text86')}</h2>
                  <p className="lead">
                    {i18n.t('text87')}
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="owl-carousel owl-theme clients-carousel dot-indicator">
                  {(this.state.trustedCompany.companies || []).map(
                    (company, index) => {
                      return (
                        <div className="item single-client" key={index}>
                          <img style={{paddingRight:10}}
                            src={company}
                            alt="client logo"
                            className="client-img"
                          />
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(TrustedCompany));
