import React, {Component} from "react";
import HeroSection from "../components/HeroSection/HeroSectionReview";
import FooterSection from "../components/Footer";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import Header from "../components/Header/header";

class PrivacyPolicy extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection  bg={'hero-bg-4.jpg'}  title={i18n.t('privacy_policy')}/>

                    <React.Fragment>
                        <section className="customer-review-section ptb-100 white-bg">
                            <div className="container">
                                <h1>{i18n.t('privacy_policy_for')} SoftNative Group LLP</h1>

                                {i18n.language === 'es' ?
                                    (
                                        <>
                                            <p> En SoftNative Group LLP, accesible desde www.softnative.dev, uno de nuestros
                                                principal
                                                La prioridad es la privacidad de nuestros visitantes. Este documento de política de privacidad contiene
                                                tipos de
                                                información recopilada y registrada por SoftNative Group LLP y cómo la usamos
                                                eso. </p>

                                            <p> Si tiene preguntas adicionales o necesita más información sobre nuestra privacidad
                                                Política, no
                                                dude en contactarnos. </p>

                                            <p> Esta Política de privacidad se aplica solo a nuestras actividades en línea y es válida para visitantes.
                                                para nuestro
                                                sitio web con respecto a la información que compartieron y / o recopilaron en Camden
                                                Cargo Express.
                                                Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales.
                                                otro que
                                                este sitio web. Nuestra Política de privacidad fue creada con la ayuda de <a
                                                    href = "https://www.privacypolicygenerator.info/"> Generador de políticas de privacidad </a>.
                                            </p>

                                            <h2> Consentimiento </h2>

                                            <p> Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta su
                                                condiciones. </p>

                                            <h2> Información que recopilamos </h2>

                                            <p> La información personal que se le solicita que proporcione y los motivos por los que
                                                pidió a
                                                proporcionarlo, se le aclarará en el momento en que le solicitemos que proporcione su
                                                personal
                                                información. </p>
                                            <p> Si se comunica con nosotros directamente, es posible que recibamos información adicional sobre usted, como
                                                tu nombre,
                                                dirección de correo electrónico, número de teléfono, el contenido del mensaje y / o archivos adjuntos que puede
                                                envíanos, y
                                                cualquier otra información que pueda optar por proporcionar. </p>
                                            <p> Cuando se registra para obtener una cuenta, es posible que le solicitemos su información de contacto, incluida
                                                artículos tales
                                                como nombre, razón social, dirección, dirección de correo electrónico y número de teléfono. </p>

                                            <h2> Cómo usamos su información </h2>

                                            <p> Usamos la información que recopilamos de varias formas, que incluyen: </p>

                                            <ul>
                                                <li> Proporcionar, operar y mantener nuestro sitio web </li>
                                                <li> Mejorar, personalizar y expandir nuestro sitio web </li>
                                                <li> Comprender y analizar cómo utiliza nuestro sitio web </li>
                                                <li> Desarrolle nuevos productos, servicios, características y funcionalidades </li>
                                                <li> Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluido
                                                    para el cliente
                                                    servicio, para proporcionarle actualizaciones y otra información relacionada con el
                                                    sitio web, y para
                                                    fines de marketing y promoción
                                                </li>
                                                <li> Enviarle correos electrónicos </li>
                                                <li> Busque y evite el fraude </li>
                                            </ul>

                                            <h2> Archivos de registro </h2>
                                            <p> SoftNative Group LLP sigue un procedimiento estándar de uso de archivos de registro.
                                                Estos archivos registran
                                                visitantes
                                                cuando visitan sitios web. Todas las empresas de hosting hacen esto y forman parte de
                                                hospedaje
                                                servicios'
                                                analítica. La información recopilada por los archivos de registro incluye Internet
                                                protocolo (IP)
                                                direcciones,
                                                tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha y hora,
                                                referir / salir
                                                páginas, y
                                                posiblemente el número de clics. Estos no están vinculados a ninguna información.
                                                eso es
                                                personalmente
                                                identificable. El propósito de la información es analizar tendencias,
                                                administrar
                                                el sitio,
                                                rastrear el movimiento de los usuarios en el sitio web y recopilar datos demográficos
                                                información. </p>

                                            <h2> Cookies y balizas web </h2>

                                            <p> Como cualquier otro sitio web, SoftNative Group LLP utiliza 'cookies'. Estas
                                                se utilizan cookies
                                                Almacenar
                                                información, incluidas las preferencias de los visitantes, y las páginas del
                                                sitio web que el
                                                visitante
                                                accedido o visitado. La información se utiliza para optimizar los usuarios
                                                experiencia por
                                                personalización
                                                el contenido de nuestra página web en función del tipo de navegador de los visitantes y / u otros
                                                información. </p>

                                            <p> Para obtener más información general sobre las cookies, lea <a
                                                href = "https://www.privacypolicyonline.com/what-are-cookies/"> "¿Qué son
                                                Cookies "</a>.
                                            </p>


                                            <h2> Políticas de privacidad de socios publicitarios </h2>

                                            <p> Puede consultar esta lista para encontrar la Política de privacidad para cada uno de los
                                                publicidad
                                                socios de
                                                SoftNative Group LLP. </p>

                                            <p> Los servidores de anuncios de terceros o las redes de anuncios utilizan tecnologías como cookies,
                                                JavaScript, o
                                                Balizas web
                                                que se utilizan en sus respectivos anuncios y enlaces que aparecen
                                                en Camden
                                                Cargo Express,
                                                que se envían directamente al navegador de los usuarios. Ellos reciben automáticamente
                                                tu dirección Ip
                                                dirección cuando esto
                                                ocurre. Estas tecnologías se utilizan para medir la eficacia de
                                                sus
                                                campañas publicitarias
                                                y / o para personalizar el contenido publicitario que ve en los sitios web
                                                que Tú
                                                visita. </p>

                                            <p> Tenga en cuenta que SoftNative Group LLP no tiene acceso ni control sobre estos
                                                galletas que
                                                son utilizados por
                                                anunciantes de terceros. </p>

                                            <h2> Políticas de privacidad de terceros </h2>
                                            <p> La política de privacidad de SoftNative Group LLP no se aplica a otros anunciantes
                                                o
                                                sitios web. Por lo tanto, nosotros
                                                le aconsejamos que consulte las respectivas Políticas de Privacidad de estos
                                                anuncio de terceros
                                                servidores para
                                                Información más detallada. Puede incluir sus prácticas y
                                                instrucciones sobre como
                                                optar por no participar
                                                de ciertas opciones. </p>

                                            <p> Puede optar por deshabilitar las cookies a través de su navegador individual
                                                opciones. Saber
                                                más detallado
                                                información sobre la gestión de cookies con navegadores web específicos, puede
                                                ser encontrado en
                                                los navegadores
                                                respectivos sitios web. </p>

                                            <h2> Derechos de privacidad de la CCPA (no vender mi información personal) </h2>

                                            <p> Según la CCPA, entre otros derechos, los consumidores de California tienen derecho
                                                para: </p>
                                            <p> Solicitar que una empresa que recopila datos personales de un consumidor divulgue
                                                la
                                                categorías y
                                                piezas específicas de datos personales que una empresa ha recopilado sobre
                                                consumidores. </p>
                                            <p> Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que
                                                un negocio
                                                posee
                                                recopilado. </p>
                                            <p> Solicite que una empresa que vende datos personales de un consumidor, no venda
                                                la
                                                personal del consumidor
                                                datos. </p>
                                            <p> Si realiza una solicitud, tenemos un mes para responderle. Si tú pudieras
                                                gusta
                                                ejercitar cualquiera de
                                                estos derechos, por favor contáctenos. </p>

                                            <h2> Derechos de protección de datos del RGPD </h2>

                                            <p> Nos gustaría asegurarnos de que conozca todos sus datos.
                                                derechos de protección.
                                                Cada usuario
                                                tiene derecho a lo siguiente: </p>
                                            <p> El derecho de acceso: tiene derecho a solicitar copias de su
                                                información personal. Nosotros
                                                puede cobrar
                                                usted una pequeña tarifa por este servicio. </p>
                                            <p> El derecho a la rectificación: tiene derecho a solicitar que
                                                corregir cualquiera
                                                información tu
                                                creer es inexacto. También tiene derecho a solicitar que
                                                completar la
                                                información tu
                                                creer es incompleto. </p>
                                            <p> El derecho a borrar: tiene derecho a solicitar que borremos su
                                                personal
                                                datos, bajo
                                                ciertas condiciones. </p>
                                            <p> El derecho a restringir el procesamiento: tiene derecho a solicitar que
                                                restringir el
                                                Procesando
                                                de sus datos personales, bajo determinadas condiciones. </p>
                                            <p> El derecho a oponerse al procesamiento: tiene derecho a oponerse a nuestra
                                                procesamiento de
                                                tu
                                                datos personales, bajo determinadas condiciones. </p>
                                            <p> El derecho a la portabilidad de datos: tiene derecho a solicitar que
                                                Transfiere el
                                                datos que nosotros
                                                haya recopilado a otra organización, o directamente a usted, bajo
                                                cierto
                                                condiciones. </p>
                                            <p> Si realiza una solicitud, tenemos un mes para responderle. Si tú pudieras
                                                gusta
                                                ejercitar cualquiera de
                                                estos derechos, por favor contáctenos. </p>

                                            <h2> Información para niños </h2>

                                            <p> Otra parte de nuestra prioridad es agregar protección a los niños mientras
                                                utilizando la
                                                Internet. Nosotros
                                                animar a los padres y tutores a observar, participar y / o
                                                monitorear y guiar
                                                sus
                                                actividad en línea. </p>

                                            <p> SoftNative Group LLP no recopila a sabiendas ninguna identificación personal
                                                Información
                                                desde
                                                niños menores de 13 años. Si cree que su hijo proporcionó este
                                                un poco
                                                información sobre
                                                nuestro sitio web, le recomendamos encarecidamente que se ponga en contacto con nosotros inmediatamente y
                                                haremos nuestro
                                                mejores esfuerzos
                                                para eliminar rápidamente dicha información de nuestros registros. </p>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <p>At SoftNative Group LLP, accessible from www.softnative.dev,
                                                one of our
                                                main
                                                priorities is the privacy of our visitors. This Privacy Policy document
                                                contains
                                                types of
                                                information that is collected and recorded by SoftNative Group LLP and
                                                how we use
                                                it.</p>

                                            <p>If you have additional questions or require more information about our
                                                Privacy
                                                Policy, do not
                                                hesitate to contact us.</p>

                                            <p>This Privacy Policy applies only to our online activities and is valid
                                                for visitors
                                                to our
                                                website with regards to the information that they shared and/or collect
                                                in Camden
                                                Cargo Express.
                                                This policy is not applicable to any information collected offline or
                                                via channels
                                                other than
                                                this website. Our Privacy Policy was created with the help of the <a
                                                    href="https://www.privacypolicygenerator.info/">Privacy Policy
                                                    Generator</a>.
                                            </p>

                                            <h2>Consent</h2>

                                            <p>By using our website, you hereby consent to our Privacy Policy and agree
                                                to its
                                                terms.</p>

                                            <h2>Information we collect</h2>

                                            <p>The personal information that you are asked to provide, and the reasons
                                                why you are
                                                asked to
                                                provide it, will be made clear to you at the point we ask you to provide
                                                your
                                                personal
                                                information.</p>
                                            <p>If you contact us directly, we may receive additional information about
                                                you such as
                                                your name,
                                                email address, phone number, the contents of the message and/or
                                                attachments you may
                                                send us, and
                                                any other information you may choose to provide.</p>
                                            <p>When you register for an Account, we may ask for your contact
                                                information, including
                                                items such
                                                as name, company name, address, email address, and telephone number.</p>

                                            <h2>How we use your information</h2>

                                            <p>We use the information we collect in various ways, including to:</p>

                                            <ul>
                                                <li>Provide, operate, and maintain our website</li>
                                                <li>Improve, personalize, and expand our website</li>
                                                <li>Understand and analyze how you use our website</li>
                                                <li>Develop new products, services, features, and functionality</li>
                                                <li>Communicate with you, either directly or through one of our
                                                    partners, including
                                                    for customer
                                                    service, to provide you with updates and other information relating
                                                    to the
                                                    website, and for
                                                    marketing and promotional purposes
                                                </li>
                                                <li>Send you emails</li>
                                                <li>Find and prevent fraud</li>
                                            </ul>

                                            <h2>Log Files</h2>

                                            <p>SoftNative Group LLP follows a standard procedure of using log files.
                                                These files log
                                                visitors
                                                when they visit websites. All hosting companies do this and a part of
                                                hosting
                                                services'
                                                analytics. The information collected by log files include internet
                                                protocol (IP)
                                                addresses,
                                                browser type, Internet Service Provider (ISP), date and time stamp,
                                                referring/exit
                                                pages, and
                                                possibly the number of clicks. These are not linked to any information
                                                that is
                                                personally
                                                identifiable. The purpose of the information is for analyzing trends,
                                                administering
                                                the site,
                                                tracking users' movement on the website, and gathering demographic
                                                information.</p>

                                            <h2>Cookies and Web Beacons</h2>

                                            <p>Like any other website, SoftNative Group LLP uses 'cookies'. These
                                                cookies are used
                                                to store
                                                information including visitors' preferences, and the pages on the
                                                website that the
                                                visitor
                                                accessed or visited. The information is used to optimize the users'
                                                experience by
                                                customizing
                                                our web page content based on visitors' browser type and/or other
                                                information.</p>

                                            <p>For more general information on cookies, please read <a
                                                href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are
                                                Cookies"</a>.
                                            </p>


                                            <h2>Advertising Partners Privacy Policies</h2>

                                            <p>You may consult this list to find the Privacy Policy for each of the
                                                advertising
                                                partners of
                                                SoftNative Group LLP.</p>

                                            <p>Third-party ad servers or ad networks uses technologies like cookies,
                                                JavaScript, or
                                                Web Beacons
                                                that are used in their respective advertisements and links that appear
                                                on Camden
                                                Cargo Express,
                                                which are sent directly to users' browser. They automatically receive
                                                your IP
                                                address when this
                                                occurs. These technologies are used to measure the effectiveness of
                                                their
                                                advertising campaigns
                                                and/or to personalize the advertising content that you see on websites
                                                that you
                                                visit.</p>

                                            <p>Note that SoftNative Group LLP has no access to or control over these
                                                cookies that
                                                are used by
                                                third-party advertisers.</p>

                                            <h2>Third Party Privacy Policies</h2>

                                            <p>SoftNative Group LLP's Privacy Policy does not apply to other advertisers
                                                or
                                                websites. Thus, we
                                                are advising you to consult the respective Privacy Policies of these
                                                third-party ad
                                                servers for
                                                more detailed information. It may include their practices and
                                                instructions about how
                                                to opt-out
                                                of certain options. </p>

                                            <p>You can choose to disable cookies through your individual browser
                                                options. To know
                                                more detailed
                                                information about cookie management with specific web browsers, it can
                                                be found at
                                                the browsers'
                                                respective websites.</p>

                                            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                                            <p>Under the CCPA, among other rights, California consumers have the right
                                                to:</p>
                                            <p>Request that a business that collects a consumer's personal data disclose
                                                the
                                                categories and
                                                specific pieces of personal data that a business has collected about
                                                consumers.</p>
                                            <p>Request that a business delete any personal data about the consumer that
                                                a business
                                                has
                                                collected.</p>
                                            <p>Request that a business that sells a consumer's personal data, not sell
                                                the
                                                consumer's personal
                                                data.</p>
                                            <p>If you make a request, we have one month to respond to you. If you would
                                                like to
                                                exercise any of
                                                these rights, please contact us.</p>

                                            <h2>GDPR Data Protection Rights</h2>

                                            <p>We would like to make sure you are fully aware of all of your data
                                                protection rights.
                                                Every user
                                                is entitled to the following:</p>
                                            <p>The right to access – You have the right to request copies of your
                                                personal data. We
                                                may charge
                                                you a small fee for this service.</p>
                                            <p>The right to rectification – You have the right to request that we
                                                correct any
                                                information you
                                                believe is inaccurate. You also have the right to request that we
                                                complete the
                                                information you
                                                believe is incomplete.</p>
                                            <p>The right to erasure – You have the right to request that we erase your
                                                personal
                                                data, under
                                                certain conditions.</p>
                                            <p>The right to restrict processing – You have the right to request that we
                                                restrict the
                                                processing
                                                of your personal data, under certain conditions.</p>
                                            <p>The right to object to processing – You have the right to object to our
                                                processing of
                                                your
                                                personal data, under certain conditions.</p>
                                            <p>The right to data portability – You have the right to request that we
                                                transfer the
                                                data that we
                                                have collected to another organization, or directly to you, under
                                                certain
                                                conditions.</p>
                                            <p>If you make a request, we have one month to respond to you. If you would
                                                like to
                                                exercise any of
                                                these rights, please contact us.</p>

                                            <h2>Children's Information</h2>

                                            <p>Another part of our priority is adding protection for children while
                                                using the
                                                internet. We
                                                encourage parents and guardians to observe, participate in, and/or
                                                monitor and guide
                                                their
                                                online activity.</p>

                                            <p>SoftNative Group LLP does not knowingly collect any Personal Identifiable
                                                Information
                                                from
                                                children under the age of 13. If you think that your child provided this
                                                kind of
                                                information on
                                                our website, we strongly encourage you to contact us immediately and we
                                                will do our
                                                best efforts
                                                to promptly remove such information from our records.</p>
                                        </>
                                    )
                                }


                            </div>
                        </section>
                    </React.Fragment>

                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(PrivacyPolicy);
