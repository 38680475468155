export const Navigation = [
    {name: 'text41', icon: 'ti-layout', nav: "#feature1"},
    {name: 'text42', icon: 'ti-car', nav: "#feature2"},
    {name: 'text43', icon: 'ti-user', nav: "#feature3"},
]
export const Title = {
    title: "title7",
    description: "text40",
    img: "https://firebasestorage.googleapis.com/v0/b/taxirodriguezweb.appspot.com/o/Web%20capture_17-10-2021_223958_rod55.com.jpeg?alt=media&token=6109fb01-21be-4645-a6bd-c3b3828577f9",
}
export const Feature1 = {
    title: "title6",
    description: "text29",
    img: "https://firebasestorage.googleapis.com/v0/b/taxirodriguezweb.appspot.com/o/download.jfif?alt=media&token=0953ad32-2224-4c6e-b9f2-1c6d77965186",
    items1: [
        {
            name: 'text34',
            description: 'text35'
        },
        {
            name: 'text36',
            description: 'text37'
        },
        {
            name: 'text38',
            description: 'text39'
        },
        {name: 'text32', description: 'text33'},
    ],
    items2: [
        {name: 'text30', description: 'text31'},
        {
            name: 'text23',
            description: 'text24'
        },

        {
            name: 'text25',
            description: 'text26'
        },
        {
            name: 'text27',
            description: 'text28'
        },
    ]
}
export const Feature2 = {
    title: "title5",
    description: "text29",
    img: "https://firebasestorage.googleapis.com/v0/b/taxirodriguezweb.appspot.com/o/18d5f941-1959-47c3-bd3f-b90c25114a25.jfif?alt=media&token=f09b9e18-9f15-40cd-887d-9d47289ad943",
    buttons: [
        {name: 'title1', icon: 'ti-mobile', href: '#tab1'},
        {name: 'title2', icon: 'ti-palette', href: '#tab2'},
        {name: 'title3', icon: 'ti-location-arrow', href: '#tab3'},
        {name: 'title4', icon: 'ti-reload', href: '#tab4'},
    ],
    tab1: [
        {
            name: 'text15',
            description: 'text16'
        },
        {
            name: 'text17',
            description: 'text18'
        },
        {
            name: 'text19',
            description: 'text20'
        },
        {
            name: 'text21',
            description: 'text22'
        },
    ],
    tab2: [
        {
            name: 'text11',
            icon: 'ti-palette icon-color-5',
            description: 'text12'
        },
        {
            name: 'text13',
            icon: 'ti-smallcap icon-color-6',
            description: 'text14'
        },

    ],
    tab3: [
        {
            name: 'text7',
            icon: 'ti-location-arrow icon-color-3',
            description: 'text8'
        },
        {
            name: 'text9',
            icon: 'ti-reload icon-color-2',
            description: 'text10'
        },

    ],
    tab4: {
        title: 'text5',
        description: "text6"
    }
}
export const Feature3 = {
    title: "passenger_app",
    description: "best_features",
    img: "img/cabs-3.png",
    items1: [
        {
            name: 'invoice_text',
            icon: 'ti-file',
            color: 'icon-color-1',
            description: 'download_text'
        },
        {
            name: 'report',
            icon: 'ti-time',
            color: 'icon-color-2',
            description: 'detailed'
        },
        {
            name: 'driver',
            icon: 'ti-time',
            color: 'icon-color-3',
            description: 'text1'
        },
    ],
    items2: [
        {
            name: 'messages',
            icon: 'ti-comments',
            color: 'icon-color-4',
            description: 'text2'
        },
        {
            name: 'bill_payments',
            icon: 'ti-credit-card',
            color: 'icon-color-5',
            description: 'text3'
        },
        {
            name: 'multi',
            icon: 'ti-world',
            color: 'icon-color-6',
            description: 'text4'
        },
    ]
}
