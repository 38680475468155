import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Theme6 from "../themes";
import NotFound from "../themes/404";
import TermsConditions from "../themes/termsConditions";
import PrivacyPolicy from "../themes/privacyPolicy";
import Contact from "../themes/contact";
import About from "../themes/about";
import Pricing from "../themes/pricing";

//solutions
import Cabs from "../themes/solutions/cabs";
import Shipment from "../themes/solutions/shipment";
import { useTranslation } from "react-i18next";

export default function Routes() {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    let language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language).catch((e) => {
        console.log(e);
      });
    }
  }, [i18n]);

  return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme6} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/terms" component={TermsConditions}/>
            <Route exact path="/privacy_policy" component={PrivacyPolicy}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/pricing" component={Pricing}/>

            <Route exact path="/cabs_software" component={Cabs}/>
            <Route exact path="/shipment_software" component={Shipment}/>
          </Switch>
        </Router>
      </React.Fragment>
  );
}
