export const Navigation = [
    {name: 'text41', icon: 'ti-layout', nav: "#feature1"},
    {name: 'text42', icon: 'ti-car', nav: "#feature2"},
    {name: 'text43', icon: 'ti-user', nav: "#feature3"},
]


export const Feature1 = {
    title: "text61",
    description: "text29",
    img: "img/shipment-1.png",
    items1: [
        {
            name: 'text57',
            description: 'text58'
        },
        {
            name: 'text59',
            description: 'text60'
        },

        {name: 'text17', description: 'text56'},
    ],
    items2: [
        {name: 'text30', description: 'text31'},
        {
            name: 'text38',
            description: 'text39'
        },

        {
            name: 'Chat',
            description: 'Messaging with clients, helps to have a record of the messages spoken with clients and have a more direct communication'
        },
    ]
}
export const Feature2 = {
    title: "title5",
    description: "text29",
    img: "https://firebasestorage.googleapis.com/v0/b/taxirodriguezweb.appspot.com/o/94f59053-31cc-4058-93a1-fb66cfe831a9.jfif?alt=media&token=c3d86619-fac7-449b-ae23-3730b423e1fe",
    buttons: [
        {name: 'title1', icon: 'ti-mobile', href: '#tab1'},
        {name: 'title3', icon: 'ti-location-arrow', href: '#tab3'},
        {name: 'title4', icon: 'ti-reload', href: '#tab4'},
    ],
    tab1: [
        {
            name: 'text49',
            description: 'text50'
        },
        {
            name: 'text51',
            description: 'text52'
        },
        {
            name: 'text53',
            description: 'text54'
        },
        {
            name: 'text32',
            description: 'text55'
        },
    ],
    tab2: [],
    tab3: [
        {
            name: 'title3',
            icon: 'ti-menu-alt icon-color-3',
            description: 'text46'
        },
        {
            name: 'text47',
            icon: 'ti-truck icon-color-2',
            description: 'text48'
        },

    ],
    tab4: {
        title: 'text44',
        description: "text45"
    }
}
export const Feature3 = {
    title: "client_application",
    description: 'best_features',
    img: "img/shipment-3.png",
    items1: [
        {
            name: 'track_shipments',
            icon: 'ti-location-pin',
            color: 'icon-color-1',
            description: 'products_services'
        },
        {
            name: 'chat_with',
            icon: 'ti-comments',
            color: 'icon-color-2',
            description: 'communicate_message'
        },
        {
            name: 'bill_payments',
            icon: 'ti-credit-card',
            color: 'icon-color-3',
            description: 'payment_history'
        },
    ],
    items2: [
        {
            name: 'request_products',
            icon: 'ti-package',
            color: 'icon-color-4',
            description: 'requesting_available'
        },
        {
            name: 'send_merchandise',
            icon: 'ti-truck',
            color: 'icon-color-5',
            description: 'register_merchandise'
        },
        {
            name: 'multi_languages',
            icon: 'ti-world',
            color: 'icon-color-6',
            description: 'language_choice'
        },
    ]
}
