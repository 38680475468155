import React from "react";
import _data from "../../_util/pricing";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import {connect} from "react-redux";

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
      projectID: 'cabs'
    };
  }

  componentDidMount() {
    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="pricing"
            className={"package-section ptb-100"} >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    <h3>{i18n.t('text125')}</h3>
                    <span style={{fontSize:20}}>{i18n.t('text126')}</span>
                    <br/>
                    <span style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('text127')}</span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="form-group input-group">
                  <select onChange={(e)=>this.setState({projectID:e.target.value})} value={this.state.projectID} className='custom-select form-control' name="cars" id="cars">
                    <option value={'cabs'}>{i18n.t('cabs_software')}</option>
                    <option value={'shipment'}>{i18n.t('shipment_software')}</option>
                  </select>
                </div>
              </div>
              </div>
              <div className="row justify-content-center">
                { this.state.projectID && (this.state.price[this.state.projectID] || []).map((_package, index) => {
                  return (
                    <div className="col-lg-5 col-md" key={`${index} ${this.state.projectID}`}>
                      <div
                        className={
                          "card text-center single-pricing-pack " +
                          (_package.isFeatured ? "popular-price" : "")
                        }
                      >
                        <div className="pt-4">
                          <h3>{i18n.t(_package.license)}</h3>
                        </div>
                        <div className="card-header border-0 pricing-header">
                        <div className="pricing-img mt-4">
                          <div className="">
                            <span style={{fontSize:70}} className={`${_package.icon} rounded mr-3 icon p-2`}/>
                          </div>

                        </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                            {(_package.features || []).map((feature, index) => {
                              return <li className='card-header border-0 pricing-header' key={index}>{i18n.t(feature)}</li>;
                            })}
                          </ul>
                          <a
                            href="/contact"
                            className={
                              "btn mb-3 " +
                              (_package.isFeatured
                                ? "solid-btn"
                                : "outline-btn")
                            }
                            target="_blank"
                          >
                            {i18n.t("contact")}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(state => ({}))(withTranslation()(Pricing));
