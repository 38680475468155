import React from "react";
import i18n from "i18next";
class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="feature3" className={"feature-section ptb-100 white-bg"}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    {i18n.t(this.props.data.title)}
                  </h2>
                  <p className='badge badge-primary badge-pill'>
                    {i18n.t(this.props.data.description)}
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                {this.props.data.items1.map((item, index) => {
                  return  <div key={item.name} className="d-flex align-items-start mb-5">
                    <div className="pr-4">
                      <div className={`icon icon-shape ${item.color} rounded-circle`}>
                        <span className={item.icon}/>
                      </div>
                    </div>
                    <div className="icon-text">
                      <h5>{i18n.t(item.name)}</h5>
                      <p className="mb-0">
                        {i18n.t(item.description)}
                      </p>
                    </div>
                  </div>
                })}
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src={this.props.data.img}
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                {this.props.data.items2.map((item, index) => {
                  return  <div key={item.name} className="d-flex align-items-start mb-5">
                    <div className="pr-4">
                      <div className={`icon icon-shape ${item.color} rounded-circle`}>
                        <span className={item.icon}/>
                      </div>
                    </div>
                    <div className="icon-text">
                      <h5>{i18n.t(item.name)}</h5>
                      <p className="mb-0">
                        {i18n.t(item.description)}
                      </p>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
