import React, {Component} from "react";
import HeroSection from "../components/HeroSection/HeroSectionReview";
import FooterSection from "../components/Footer";
import PricingSection from "../components/Pricing";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import Header from "../components/Header/header";

class TermsConditions extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection bg={'pricing.jpeg'}  title={i18n.t('pricing')}/>
                    <PricingSection hideFaq={false} />
                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(TermsConditions);

