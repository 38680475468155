import React, {Component} from "react";
import HeroSection from "../components/HeroSection/HeroSectionReview";
import FooterSection from "../components/Footer";
import {withTranslation} from "react-i18next";
import ContactSection from "../components/Contact";
import i18n from "i18next";
import Header from "../components/Header/header";

class TermsConditions extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection bg={'hero-bg-4.jpg'}  title={i18n.t('contact')}/>
                    <ContactSection />
                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(TermsConditions);
