import React from "react";
import {connect} from "react-redux";
import i18n from "i18next";
import {withTranslation} from "react-i18next";

class Header extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className='container'>
                    <div className="row justify-content-center pt-5">
                        <ul className="nav nav-tabs feature-tab">
                            {this.props.navigation.map((x, index)=>{
                                return (
                                    <li key={index} className="nav-item">
                                        <a className="nav-link h6 page-scroll" href={i18n.t(x.nav)}>
                                            <span className={x.icon}/>
                                            {i18n.t(x.name)}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => ({}))(withTranslation()(Header));
