import React, {Component} from "react";
import HeroSection from "../../components/HeroSection/HeroSectionReview";
import FooterSection from "../../components/Footer";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import Header from "../../components/Header/header";
import NavigationComponents from "./components/Navigation";
import Feature1Components from "./components/Feature1";
import FeatureTitleComponents from "./components/FeatureTitle";
import Feature2Components from "./components/Feature2";
import Feature3Components from "./components/Feature3";
import {Navigation, Title, Feature1, Feature2, Feature3} from "./components/_cabs_info";


class TermsConditions extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection bg={"cabs.jpeg"} position={'bottom'} title={i18n.t('cabs_software')}/>
                    <NavigationComponents navigation={Navigation}/>
                    <FeatureTitleComponents id={'feature1'}  data={Title}/>
                    <Feature1Components id={'feature1'} data={Feature1} />
                    <Feature3Components id={'feature2'} data={Feature2}/>
                    <Feature2Components id={'feature3'} data={Feature3}/>
                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(TermsConditions);
