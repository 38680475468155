import React from "react";
import { connect } from "react-redux";
import { getQuote } from "../../actions/index";
import i18n from "i18next";
import {withTranslation} from "react-i18next";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      trustedCompany: [],
      video: "",
      name: "",
      email: "",
      subject: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      isSend: false,
      app: '',
      btnText: "send2",
      btnTextTrial: "Free Trial"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });


    let url = "https://us-central1-softnative-group.cloudfunctions.net/sendContact"
    fetch(url, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.app +': '+ this.state.message,
        company: this.state.company,
      }),
    })
      .then(response => response.json())
      .then(data => {
        this.setState({sendSuccess: 'send_success', isSend:true});
        setTimeout(() => {
          this.setState({sendSuccess: ''});
        }, 5000)

      })
      .catch((error) => {
        console.error('Error:', error);
      });

    const quoteAction = getQuote(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(quoteAction);

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Send");

        // get action again to update state
        const quoteAction = getQuote(this.state);

        // Dispatch the contact from data
        this.props.dispatch(quoteAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          isAgreed: false
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section background-img pt-100"
          style={{
            backgroundImage: "url('img/hero-bg-4.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 mb-lg-5">
                <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                  <h1 className="text-white">
                    <span>{i18n.t('text117')}</span>
                  </h1>
                  <p className="lead" style={{fontSize:23}}>
                    {i18n.t('text118')}
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                  <div className="sign-up-form-header text-center mb-4">
                    <h4 className="mb-0">{i18n.t('text119')}</h4>
                    <p>{i18n.t('text120')}</p>
                  </div>
                  <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input-group">
                      <input
                        value={this.state.name}
                        onChange={e => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder={i18n.t('text121')}
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.email}
                        onChange={e => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder={i18n.t('text122')}
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <select onChange={(e)=>this.setState({app:e.target.value})} className='custom-select form-control' name="cars" id="cars">
                        <option value="build">{i18n.t('text123')}</option>
                        <option value="Cabs">{i18n.t('cabs_software')}</option>
                        <option value="shipment">{i18n.t('shipment_software')}</option>
                      </select>
                    </div>
                    <div className="form-group input-group">
                      <textarea
                        onChange={e => this.handleFormValueChange("message", e)}
                        value={this.state.message}
                        name="message"
                        id="msg"
                        className="form-control"
                        placeholder={i18n.t('text124')}
                        cols="30"
                        rows="4"
                        required
                      />
                    </div>
                    {this.state.isSend && (<b style={{color:'green'}}>{i18n.t('message_sent')}</b>)}
                    <div className="form-group">
                      <input
                        onClick={() => {
                          this.changeBtnText(i18n.t('text106'));
                        }}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block"
                        value={i18n.t(this.state.btnText)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="section-shape position-absolute">
            <img src="img/wave-shap.svg" alt="shape" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(HeroSection));
