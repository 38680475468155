import React from "react";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import i18n from "i18next";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'es'
        };
    }

    componentDidMount() {
        let language = localStorage.getItem("language");
        if (language) {
            this.setState({language: language})
            i18n.changeLanguage(language).catch((e) => {
                console.log(e);
            });
        }
    }

    changeLang = async (lang) => {
        try {
            localStorage.setItem("language", lang);
            this.setState({language: lang})
            await i18n.changeLanguage(lang);
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <nav
                        className={"navbar navbar-expand-lg fixed-top " + (this.props.bgColor && this.props.bgColor === 'white' ? 'custom-nav white-bg' : 'bg-transparent')}>
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img
                                    src={"img/logo.png"}
                                    width="200"
                                    alt="logo"
                                    className="img-fluid"
                                />
                            </a>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="ti-menu"/>
                            </button>

                            <div
                                className="collapse navbar-collapse main-menu"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link page-scroll" href="/pricing">
                                            {i18n.t('pricing')}
                                        </a>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link page-scroll dropdown-toggle"
                                            href="/#"
                                            id="navbarDropdownSolutions"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {i18n.t('solutions')}
                                        </a>
                                        <div
                                            className="dropdown-menu submenu"
                                            aria-labelledby="navbarDropdownSolutions"
                                        >
                                            <a className="dropdown-item" href="/cabs_software">
                                                {i18n.t('cabs_software')}
                                            </a>
                                            <a className="dropdown-item" href="/shipment_software">
                                                {i18n.t('shipment_software')}
                                            </a>
                                        </div>
                                    </li>


                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link page-scroll dropdown-toggle"
                                            href="/#"
                                            id="navbarDropdownCompany"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {i18n.t('company')}
                                        </a>
                                        <div
                                            className="dropdown-menu submenu"
                                            aria-labelledby="navbarDropdownCompany"
                                        >
                                            <a className="dropdown-item" href="/about">
                                                {i18n.t('about')}
                                            </a>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link page-scroll dropdown-toggle"
                                            href="/#"
                                            id="navbarDropdownSolutions"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {i18n.t('language')}
                                        </a>
                                        <div
                                            className="dropdown-menu submenu"
                                            aria-labelledby="navbarDropdownSolutions"
                                        >
                                            <div style={{
                                                backgroundColor: this.state.language === 'es' ? '#085ca7' : 'transparent',
                                                color: this.state.language === 'es' ? '#fff' : '#000',
                                                borderRadius: 5,
                                                cursor: "pointer"

                                            }} onClick={() => this.changeLang('es')} className="dropdown-item">
                                                {i18n.t('spanish')}
                                            </div>
                                            <div style={{
                                                backgroundColor: this.state.language === 'en' ? '#085ca7' : 'transparent',
                                                color: this.state.language === 'en' ? '#fff' : '#000',
                                                borderRadius: 5,
                                                cursor: "pointer"
                                            }} onClick={() => this.changeLang('en')} className="dropdown-item">
                                                {i18n.t('english')}
                                            </div>
                                        </div>
                                    </li>

                                    <li className="nav-item">
                                        <a className="text-center nav-link page-scroll solid-btn-no1" href="/contact">
                                            <b>
                                                {i18n.t('text65')}
                                            </b>
                                        </a>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <a target={'_blank'} className="text-center nav-link page-scroll solid-btn-no" href="https://dashboard.softnative.dev">*/}
                                    {/*        <b>*/}
                                    {/*            {i18n.t('login')}*/}
                                    {/*        </b>*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}

export default connect(state => ({}))(withTranslation()(Header));
