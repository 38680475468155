module.exports = {
  price: {
    cabs: [
      {
        license: "text128",
        icon:'ti-medall-alt icon-color-2',
        isFeatured: false,
        features: [
          "text129",
          "text130",
          "text131",
          "text132",
          "text133",
          "text134",
          "text135",
          "text136",
          "text137",
          "text138",
          "text139",
          "text140",
          "text141",
          "text142",
          "text143",
          "report",
          "text144",
          "text145",
          "multi_languages",
          "---",
          "text146",
          "text147",
          "---",
          "---",
        ]
      },
      {
        icon:'ti-medall icon-color-4',
        license: "Extended",
        isFeatured: true,
        features: [
          "text129",
          "text130",
          "text131",
          "text132",
          "text133",
          "text134",
          "text135",
          "text136",
          "text137",
          "text138",
          "text139",
          "text140",
          "text141",
          "text142",
          "text143",
          "report",
          "text144",
          "text145",
          "multi_languages",
          "text148",
          "text146",
          "text147",
          "text149",
          "text150",
        ]
      }
    ],
    shipment: [
      {
        license: "text128",
        icon:'ti-medall-alt icon-color-2',
        isFeatured: false,
        features: [
          "text131",
          "text129",
          "text132",
          "text133",
          "text135",
          "text138",
          "text151",
          "text140",
          "text152",
          "text17",
          "text144",
          "text153",
          "text154",
          "multi_languages",
          "---",
          "text146",
          "text61",
          "---",
          "---",
        ]
      },
      {
        icon:'ti-medall icon-color-4',
        license: "Extended",
        isFeatured: true,
        features: [
          "text131",
          "text129",
          "text132",
          "text133",
          "text135",
          "text138",
          "text151",
          "text140",
          "text152",
          "text17",
          "text144",
          "text153",
          "text154",
          "multi_languages",
          "text155",
          "text146",
          "text61",
          "text149",
          "text150",
        ]
      }
    ],
  },
  testimonial: {
    title: "What customer say about us",
    description:
        'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Oneida Fortuna",
        company: "Five Star Cab Services",
        image: "img/companies/c2.jpg",
        comment:
            "testimonial1"
      },
      {
        name: "Pablo Garcia",
        company: "Ecuamerica",
        image: "img/companies/c1.jpg",
        comment:
            "testimonial2"
      },
      {
        name: "Lorenzo Hernandez",
        company: "Camden Cargo Express",
        image: "img/companies/c4.jpg",
        comment:
            "testimonial3"
      },
    ]
  },
  trustedCompanyAboutUs: {
    companies: [
      "img/companies/1.jpg",
      "img/companies/2.jpg",
      "img/companies/3.jpg",
      "img/companies/4.jpg",
    ]
  },
};
