import React from "react";
import i18n from "i18next";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section id={this.props.id} className="feature-section white-bg">
                    <div className="container p-5">
                        <div className="flex-column">
                                <h2 className="mt-4">{i18n.t(this.props.data.title)}</h2>
                            <span className='badge badge-primary badge-pill'>
                                {i18n.t(this.props.data.description)}
                            </span>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <ul className="list-unstyled">
                                        {this.props.data.items2.map((item, index) => {
                                            return <li key={item.name} className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="badge badge-circle badge-primary mr-3">
                                                            <span className="ti-check"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 font-weight-bolder text-dark">{i18n.t(item.name)}</p>
                                                        <small className="mb-0">{i18n.t(item.description)}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        })}
                                    </ul>

                                </div>
                                <div className='col-sm-6'>
                                    <ul className="list-unstyled">
                                        {this.props.data.items1.map((item, index) => {
                                            return <li key={item.name} className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="badge badge-circle badge-primary mr-3">
                                                            <span className="ti-check"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 font-weight-bolder text-dark">{i18n.t(item.name)}</p>
                                                        <small className="mb-0">{i18n.t(item.description)}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        })}
                                    </ul>
                                </div>

                            </div>

                                <div className="about-content-right">
                                    <img
                                        src={this.props.data.img}
                                        width={'100%'}
                                        alt="about us"
                                        className="img-full"
                                        style={{border: "10px solid black", borderRadius: "25px"}}
                                    />
                                </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default About;
