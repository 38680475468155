import React from "react";
import { connect } from "react-redux";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 gray-light-bg"}>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-10">
                <div className="section-heading mb-5">
                  <h5 className="h5 mt-3 mb-6">
                    {i18n.t('text66')}
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-lg-0">
                <div className="card single-promo-card single-promo-hover">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-light-bulb icon-md color-secondary"/>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>{i18n.t('text67')}</h5>
                      <p className="text-muted mb-0">
                        {i18n.t('text68')} <br/> {i18n.t('text69')} = Softnative Group
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-user icon-md color-secondary"/>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>{i18n.t('text70')}</h5>
                      <p className="text-muted mb-0">
                        {i18n.t('text71')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-mobile icon-md color-secondary"/>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>{i18n.t('text72')}</h5>
                      <p className="text-muted mb-0">
                        {i18n.t('text73')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withTranslation()(PromoSection));
