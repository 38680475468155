import React, {Component} from "react";
import HeroSection from "../components/HeroSection/HeroSection2";
import FooterSection from "../components/Footer";
import PromoSection from "../components/PromoSection/Promo2";
import {withTranslation} from "react-i18next";
import Header from "../components/Header/header";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";

class TermsConditions extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection bg={'hero-bg-4.jpg'} />
                    <PromoSection />
                    <TrustedCompany/>
                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(TermsConditions);
